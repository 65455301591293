<script setup>
import {ref, computed} from 'vue';
import { StarIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { storeToRefs } from 'pinia';

import { usePlusSurveyStore } from '../store/plusSurveyStore';
const plusSurveyStore = usePlusSurveyStore();
const { subjects, plusExamSurvey} = storeToRefs(plusSurveyStore);
const { fetchPlusExamSurvey } = plusSurveyStore;

const aveRating = computed(()=>{
    let totalRating = 0;
    plusExamSurvey.value.forEach((survey)=>{
        totalRating = totalRating + survey.data.rating;
    })

    return totalRating / plusExamSurvey.value.length;
})

const init = async() => {
    console.log('init...')
    await fetchPlusExamSurvey();
}

init();

</script>
<template>
    <div class=" flex justify-center text-warm-gray-300">
        
        <div class=" max-w-3xl mt-8 w-full">
            <div class=" flex justify-between items-center">
                <h1 class=" text-xl font-semibold text-warm-gray-100">CAAP Practical Post Exam Survey</h1>
                <div>
                    <button @click="fetchPlusExamSurvey(true)" class=" rounded-full w-6 h-6 p-0.5 bg-warm-gray-700 bg-opacity-60 hover:opacity-90"><ArrowPathIcon class="w-full h-auto"/></button>
                </div>
            </div>
            <div>
                <p class=" mt-8">Overall Rating:</p>
                <div class=" flex justify-start items-center mt-2 ml-2">
                    <b class=" text-xl text-yellow-300 mr-1">{{ aveRating ? aveRating : 0 }}</b> 
                    <span>
                        <StarIcon class="w-4 h-4 text-yellow-300"/>
                    </span>
                </div>
            </div>
            <div class="mt-8">
                <p class=" mt-4">Total Responses:</p>
                <div class=" flex justify-start items-center mt-2 ml-2">
                    <b class=" text-xl mr-1">{{ plusExamSurvey.length }}</b>
                </div>
            </div>


            <div class=" mt-8 rounded-lg overflow-hidden">

                <table class="w-full">
                    <tr class=" bg-warm-gray-700">
                        <th class=" text-left p-2">Subject Name</th>
                        <th class=" text-center p-2">Topics</th>
                        <th></th>
                    </tr>
                    <tr v-for="subject in subjects" class=" border-b border-dashed border-warm-gray-700">
                        <td class="p-2 text-left">{{ subject.name }}</td>
                        <td class="p-2 text-center">{{ subject.topics.length }}</td>
                        <td class="p-2 text-center">
                            <router-link :to="'/plus-survey/'+subject.id" class=" text-yellow-600 hover:underline">View Detail</router-link>
                        </td>
                    </tr>
                </table>

            </div>

        </div>

    </div>
</template>